<template>
  <div>
    <Row>
      <Col span="24">中图地图</Col>
    </Row>
    <Row>
      <Col span="24">统计数据</Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "routineAnalysis"
}
</script>

<style scoped>

</style>